import React, { useMemo } from "react";
import { Button } from "./ui/button";

interface MinimalFooterProps {
    buttonLabel?: string;
    onSubmit?: () => void;
    disabled?: boolean;
}

const PixelBoxes = ({ pattern }: any) => {
    const colorStyle = (color: string) => {
        const hexColors: any = {
            red: "#FD5939",
            white: "#FFFFFF",
            yellow: "#FDFD43",
            blue: "#2937FF",
            pink: "#F378FF",
            black: "#161618",
        };
        return hexColors[color];
    };

    return (
        <div>
            <div className="flex flex-col w-full">
                {pattern.map((row: string[], idx: number) => {
                    return (
                        <div key={`${row[idx]}-${idx}`} className="grid grid-cols-6 gap-0 h-[50%]">
                            {row.map((color: string, idx: number) => {
                                return (
                                    <div key={`${color}-${idx}`} style={{ backgroundColor: colorStyle(color) }} className={"w-16 h-16 block"}>
                                        &nbsp;
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const MinimalFooter = ({ buttonLabel, onSubmit, disabled }: MinimalFooterProps) => {
    const pixels = useMemo(() => {
        const pattern = [
            ["white", "blue", "yellow", "red"],
            ["yellow", "pink", "white", "yellow", "pink", "black"],
        ];
        return <PixelBoxes pattern={pattern} />;
    }, []);

    return (
        <div className=" bg-brand-yellow w-full fixed bottom-0 left-0">
            <div className="flex gap-4 h-20 items-center justify-center px-10 wizard_nav_buttonbar">
                {buttonLabel &&
                    <Button
                        onClick={onSubmit}
                        variant="primary-negative"
                        disabled={disabled}
                        className="disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400 disabled:border-none"
                    // onClick={onSubmit}
                    // disabled={submitting}
                    // onClick={onSubmit}
                    >
                        {buttonLabel}
                    </Button>
                }
            </div>
            <div className="max-w-[400px] flex flex-grow">{pixels}</div>
        </div>
    )
}

export default MinimalFooter;