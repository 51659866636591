import React, { useRef, useEffect, useState } from "react";
import cursorImage from "../../../../brush.png";

interface ImageEditCanvasProps {
    image: string;
    width?: number;
    cursorSize: number;
    drawnPortion: { x: number; y: number; radius: number }[][];
    setDrawnPortion: React.Dispatch<React.SetStateAction<{ x: number; y: number; radius: number }[][]>>;
    resetCanvas: () => void;
    handleMaskGenerated: (mask: string) => void;
}

const ImageEditCanvas: React.FC<ImageEditCanvasProps> = ({
    image,
    width = 600,
    cursorSize,
    drawnPortion,
    setDrawnPortion,
    handleMaskGenerated
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
    const [imageAspectRatio, setImageAspectRatio] = useState(1);
    const [isDrawing, setIsDrawing] = useState(false);
    const [currentStroke, setCurrentStroke] = useState<{ x: number; y: number; radius: number }[]>([]);
    const [customCursor, setCustomCursor] = useState<string | null>(null);

    useEffect(() => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
            setImageElement(img);
            setImageAspectRatio(img.width / img.height);
        };
    }, [image]);

    const updateCursorImage = () => {
        const tempCanvas = document.createElement("canvas");
        const ctx = tempCanvas.getContext("2d");
        const img = new Image();

        img.src = cursorImage;
        img.onload = () => {
            tempCanvas.width = cursorSize;
            tempCanvas.height = cursorSize;

            if (ctx) {
                ctx.drawImage(img, 0, 0, cursorSize, cursorSize);
                const resizedCursorURL = tempCanvas.toDataURL("image/png");
                setCustomCursor(resizedCursorURL);
            }
        };
    };

    useEffect(() => {
        updateCursorImage();
    }, [cursorSize]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");

        if (canvas && context && imageElement) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);

            drawnPortion.forEach((stroke) => {
                stroke.forEach(({ x, y, radius }) => {
                    context.beginPath();
                    context.arc(x, y, radius, 0, 2 * Math.PI);
                    context.fillStyle = "white";
                    context.fill();
                });
            });
        }
    }, [drawnPortion, imageElement]);

    const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
        setIsDrawing(true);
        setCurrentStroke([]);
        inpaint(event);
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (isDrawing) {
            inpaint(event);
        }
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
        if (currentStroke.length > 0) {
            setDrawnPortion((prev) => [...prev, currentStroke]);
            setCurrentStroke([]);
        }

        generateMask();
    };

    const inpaint = (event: React.MouseEvent<HTMLCanvasElement>) => {
        const canvas = canvasRef.current;

        if (!canvas) return;

        const context = canvas.getContext("2d");
        if (!context || !imageElement) return;

        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const radius = cursorSize / 2;

        context.beginPath();
        context.arc(x, y, radius, 0, 2 * Math.PI);
        context.fillStyle = "white";
        context.fill();

        setCurrentStroke((prev) => [...prev, { x, y, radius }]);
    };

    const generateMask = () => {
        const maskCanvas = document.createElement("canvas");
        const maskContext = maskCanvas.getContext("2d");

        if (!maskContext || !canvasRef.current) return;

        const width = canvasRef.current.width;
        const height = canvasRef.current.height;

        maskCanvas.width = width;
        maskCanvas.height = height;

        maskContext.fillStyle = "black";
        maskContext.fillRect(0, 0, width, height);

        drawnPortion.forEach((stroke) => {
            stroke.forEach(({ x, y, radius }) => {
                maskContext.beginPath();
                maskContext.arc(x, y, radius, 0, 2 * Math.PI);
                maskContext.fillStyle = "white";
                maskContext.fill();
            });
        });

        const maskBase64 = maskCanvas.toDataURL("image/png");
        handleMaskGenerated(maskBase64);

        // const maskImageElement = document.getElementById("mask-preview") as HTMLImageElement;
        // if (maskImageElement) {
        //     maskImageElement.src = maskBase64;
        // }
    };

    const inpaintingCursor: React.CSSProperties = {
        cursor: customCursor ? `url(${customCursor}) ${cursorSize / 2} ${cursorSize / 2}, auto` : "auto",
    };

    const calculatedHeight = width / imageAspectRatio;

    return (
        <div>
            <canvas
                ref={canvasRef}
                width={width}
                height={calculatedHeight}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                className="rounded-lg"
                style={inpaintingCursor}
            />
            {/* 
            <div>
    <button onClick={generateMask}>Generate Mask</button>
    <div>
        <h3>Mask Preview:</h3>
        <img id="mask-preview" alt="Mask Preview" />
    </div>
</div> */}
        </div>
    );
};

export default ImageEditCanvas;
