import React, { useEffect, useState } from "react";
import "./image_generator.css";
import Heading from "../../common/components/heading";
import { Button } from "../../common/components/ui/button";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { useParams, useNavigate } from "react-router-dom";
import {
    GenerateMediaResult, GenerativeModelWeight, SelectedModels,
    GenerativeModel,
    GenerativeOutput,
    UpscaleRequest
} from "./models/image_generator";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import {
    CopyIcon,
    UpscaleIcon,
    DownloadIcon,
    EditAspectRatioIcon,
    EditDirectlyIcon,
    EditPromptIcon,
    SaveIcon,
    IconArrowLeft,
    SelectedGenIcon,
} from "../../common/icons/icons";
import LoadingPage from "./loading_page";
import AspectRatioContent from "./components/modal-components/aspect_ratio";
import EditPrompt from "./components/modal-components/edit_prompt";
// import SaveImageContent from "./components/modal-components/save_image";
import ImageEditCanvas from "./components/modal-components/image_edit_canvas";
import MinimalFooter from "../../common/components/minimal_footer";
import VaultDropdown from "./components/vault_dropdown";
import { Sparkles } from "../../common/icons/icons";
import loading from "../../common/components/ui/loading.gif";

const GeneratedImagePage = () => {
    const { taskQueueId: initialTaskQueueId } = useParams<{ taskQueueId: string }>();
    const [taskQueueId, setTaskQueueId] = useState<string | null>(initialTaskQueueId || null);
    const [generativeData, setGenerativeData] = useState<GenerateMediaResult | null>(null);
    const [selectedVariant, setSelectedVariant] = useState<GenerativeOutput>();
    const [variations, setVariations] = useState<GenerativeOutput[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [showLoadingPage, setShowLoadingPage] = useState(false);
    const [progress, setProgress] = useState({ percent_complete: 0, progress_message: "Generating image..." });
    const [inpaintingView, setInpaintingView] = useState(false);
    const [cursorSize, setCursorSize] = useState(32);
    const [drawnPortions, setDrawnPortions] = useState<{ x: number; y: number; radius: number }[][]>([]);
    const [inpaintPrompt, setInpaintPrompt] = useState<string>("");
    const [isPromptLoading, setIsPromptLoading] = useState(false);
    const [mask, setMask] = useState<string | null>(null);
    const [modelList, setModelList] = useState<GenerativeModel[]>([]);
    const [selectedModels, setSelectedModels] = useState<SelectedModels>({
        product: null,
        brand: null,
        talent: null,
    })

    const [scale, setScale] = useState(1);

    const [selectedModel, setSelectedModel] = useState<GenerativeModel | null>(null);

    const [selectedModelId, setSelectedModelId] = useState("");


    const [actionToast, setActionToast] = useState<
        {
            showToast: boolean,
            toastMessage: string,
        }>({
            showToast: false,
            toastMessage: "",
        });
    // TODO combine vertical modal & modal content
    const [modalContent, setModalContent] = useState<React.ReactNode>(null);
    const [isVerticalModal, setIsVerticalModal] = useState(false);
    const hasFetched = React.useRef(false);

    const { getGenerativeOutput, getModels, saveMyImage, upscaleImage, generateImageMedia, checkTaskQueueStatus, enhancePrompt } = useGenerativeAPI();
    const navigate = useNavigate();
    let intervalId: NodeJS.Timeout;

    const downloadImage = () => {
        if (!generativeData) return;
        const link = document.createElement("a");
        link.href = generativeData?.outputs[0].permalink;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast("Image downloaded");
    }

    const saveImage = async () => {
        setModalOpen(false);
        if (!taskQueueId || !generativeData) {
            showToast("Error saving image");
            return;
        }
        try {
            await saveMyImage(taskQueueId);
            showToast("Image saved to My Images");
        } catch (error) {
            showToast("Error saving image");
            console.error("Error saving image", error);
        }
    }

    const fetchUpscaleImage = async () => {
        if (!selectedVariant || !taskQueueId || !generativeData?.seed) {
            showToast("Error upscaling image");
            return;
        }

        const payload: UpscaleRequest = {
            generative_output_id: selectedVariant.generative_output_id,
            file_id: selectedVariant.file_id,
            seed: generativeData?.seed
        }

        try {
            await upscaleImage(payload);
            intervalId = setInterval(() => checkStatus(taskQueueId, intervalId), 1000);
            setTimeout(() => clearInterval(intervalId), 2 * 60 * 1000);
        } catch (error) {
            console.error();
            showToast("Error upscaling image");
        }
    }

    // TODO consolidate methods
    const handleSavePrompt = (newPrompt: string) => {
        setModalOpen(false);
        setGenerativeData((prevData) => {
            if (!prevData) return null;
            return { ...prevData, prompt: newPrompt }
        });
        regenerateImage(newPrompt);
    }

    // TODO fix regenerateImage methods
    const regenerateImage = async (prompt?: string, aspect_ratio?: string, model_weights?: GenerativeModelWeight[]) => {
        setShowLoadingPage(true);
        try {
            const response = await generateImageMedia({
                prompt: prompt || generativeData?.prompt || "",
                aspect_ratio: aspect_ratio || "SQUARE",
                image_file_id: selectedVariant?.file_id || "",
                model_weights: model_weights || generativeData?.model_weights || [],
                seed: generativeData?.seed
            });
            setTaskQueueId(response.id);

            intervalId = setInterval(() => checkStatus(response.id, intervalId), 1000);
            setTimeout(() => clearInterval(intervalId), 2 * 60 * 1000);
        } catch (error) {
            console.error("Error generating image", error);
        }
    }


    const enhanceTextPrompt = async () => {
        setIsPromptLoading(true);

        const models = [];
        models.push(selectedModelId);

        const response = await enhancePrompt(inpaintPrompt, models);
        if (response && response.enhanced) {
            setInpaintPrompt(response.enhanced);
        }
        setIsPromptLoading(false);
    }


    const generateInpaint = async () => {
        resetCanvas();
        setInpaintPrompt("");
        setInpaintingView(false);
        setShowLoadingPage(true);

        const modelWeights = [];
        if (selectedModel?.entity_type === "LICENSABLE_PROPERTY") {
            modelWeights.push({ id: selectedModel.id, weight: 0.8 });
        }
        if (selectedModel?.entity_type === "USER_PRODUCT") {
            modelWeights.push({ id: selectedModel.id, weight: (modelWeights.length === 0 ? 0.8 : 0.2) });
        }
        try {
            const response = await generateImageMedia({
                prompt: inpaintPrompt,
                aspect_ratio: generativeData?.aspect_ratio || "SQUARE",
                image_file_id: selectedVariant?.file_id || "",
                model_weights: modelWeights || [],
                seed: generativeData?.seed,
                mask: mask || ""
            });
            setTaskQueueId(response.id);

            intervalId = setInterval(() => checkStatus(response.id, intervalId), 1000);
            setTimeout(() => clearInterval(intervalId), 2 * 60 * 1000);
        } catch (error) {
            console.error("Error generating image", error);
        }
    }

    const buttonMap = [
        {
            label: "Generate variations",
            icon: <CopyIcon />,
            action: () => regenerateImage("")
        },
        {
            label: "Upscale",
            icon: <UpscaleIcon />,
            action: () => fetchUpscaleImage()
        },
        {
            label: "Download",
            icon: <DownloadIcon />,
            action: () => downloadImage()
        },
        {
            label: "Edit aspect ratio",
            icon: <EditAspectRatioIcon />,
            action: () => openModal(<AspectRatioContent currentAspectRatio={generativeData?.aspect_ratio} onSave={editAspectRatio} />)
        },
        {
            label: "Edit directly",
            icon: <EditDirectlyIcon />,
            action: () => setInpaintingView(true)
        },
        {
            label: "Edit prompt",
            icon: <EditPromptIcon />,
            action: () => openModal(<EditPrompt
                prompt={generativeData?.prompt || ""}
                onSave={handleSavePrompt} selectedModels={generativeData?.model_weights} />
            )
        },
        {
            label: "Save",
            icon: <SaveIcon />,
            // Save image displays toast until functionality for saving to project is implemented
            // action: () => openModal(SaveImageContent(saveImage), true),
            action: () => saveImage()
        },
    ]

    const editAspectRatio = (newAspectRatio: string) => {
        setModalOpen(false);
        setGenerativeData((prevData) => {
            if (!prevData) return null;
            return { ...prevData, aspect_ratio: newAspectRatio }
        });
        regenerateImage("", newAspectRatio);
    };

    useEffect(() => {
        if (!hasFetched.current) {
            if (taskQueueId) {
                const fetchGenerativeOutput = async () => {
                    try {
                        const response = await getGenerativeOutput(taskQueueId);
                        setGenerativeData(response);
                        const variations = response.outputs.map(output => ({
                            generative_output_id: output.generative_output_id,
                            file_id: output.file_id,
                            permalink: output.permalink,
                            asset_type: output.asset_type,
                        }))
                        setVariations(variations);
                        setSelectedVariant(variations[0]);
                    } catch (error) {
                        console.error("Error fetching generative output", error);
                    }
                };

                fetchGenerativeOutput();
                hasFetched.current = true;
            }
        }
    }, [taskQueueId, getGenerativeOutput]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [productResponse, brandResponse, talentResponse] = await Promise.all([
                    getModels("USER_PRODUCT"),
                    getModels("BRAND"),
                    getModels("LICENSABLE_PROPERTY"),
                ]);

                const combinedModels = [
                    ...productResponse.map(model => ({ ...model, model_type: "Product" })),
                    ...brandResponse.map(model => ({ ...model, model_type: "Brand" })),
                    ...talentResponse.map(model => ({ ...model, model_type: "Talent" })),
                ]
                setModelList(combinedModels);
            } catch (error) {
                console.error("Error fetching models", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const prompt = inpaintPrompt || "";

        if (!selectedModel) return;
        if (!prompt.includes(selectedModel?.generative_tag)) {
            setSelectedModel(null);
            setSelectedModelId("");
        }
    }, [inpaintPrompt]);

    const openModal = (content: React.ReactNode, verticalModal?: boolean) => {
        setIsVerticalModal(verticalModal || false);
        setModalContent(content);
        setModalOpen(true);
    }

    const showToast = (message: string) => {
        setModalOpen(false);
        setActionToast({ showToast: true, toastMessage: message });
        setTimeout(() => {
            setActionToast(prevState => ({ ...prevState, showToast: false, toastMessage: "" }));
        }, 3000);
    }

    const increaseCursorSize = () => {
        setCursorSize((prevSize) => prevSize + 10);
    }

    const decreaseCursorSize = () => {
        setCursorSize((prevSize) => Math.max(prevSize - 10, 10));
    }

    const undoLastDraw = () => {
        setDrawnPortions((prev) => prev.slice(0, -1));
    };

    const resetCanvas = () => {
        setDrawnPortions([]);
    };

    const generateMask = (base64Mask: any) => {
        setMask(base64Mask);
    }

    const handleSelectModel = (model: any) => {
        setSelectedModel(model);
        setSelectedModelId(model?.id || "");
        const regex = /#\w+/g;
        setInpaintPrompt((prevPrompt) => {
            const promptWithoutOldTag = prevPrompt.replace(regex, "").trim();

            const newGenerativeTag = `#${model.generative_tag}`;
            const updatedPrompt = `${promptWithoutOldTag} ${newGenerativeTag}`.trim();

            return updatedPrompt;
        });
    }

    const checkStatus = async (updatedTaskQueueId: string, intervalId: NodeJS.Timeout) => {
        try {
            const response = await checkTaskQueueStatus(updatedTaskQueueId);
            let failedTask = null;
            let completedTask = null;
            for(let i = response.length - 1; i >= 0; i--) {
                if(response[i].status === "completed"){
                    completedTask = response[i];
                    break;
                }
                if(response[i].status === "failed"){
                    failedTask = response[i];
                    break;
                }
            }
            if (failedTask) {
                setShowLoadingPage(false);
                clearInterval(intervalId);
                showToast("Failed to generate image: " + failedTask.progress_message);
                return;
            }
            if (completedTask && completedTask.progress_status === "failed") {
                setShowLoadingPage(false);
                clearInterval(intervalId);
                showToast("Failed to generate image: " + completedTask.progress_message);
                return;
            }

            if (completedTask && completedTask.progress_status === "completed") {
                clearInterval(intervalId);
                setShowLoadingPage(false);
                navigate(`/image/editor/${updatedTaskQueueId}`);
                hasFetched.current = false;
                return;
            }

            const latestTask = response[response.length - 1];

            setProgress({
                percent_complete: latestTask.percent_complete || 0,
                progress_message: latestTask.progress_message
            })

        } catch (error) {
            console.error("Error checking status", error);
        }
    }

    const handleZoomIn = () => { setScale((prev) => Math.min(prev + 0.1, 3)) };
    const handleZoomOut = () => { setScale((prev) => Math.max(prev - 0.1, 0.5)) };

    return (
        <div>
            {/* TODO laoding page has padding bottom.. */}
            {showLoadingPage ? <LoadingPage percentComplete={progress.percent_complete} progressMessage={progress.progress_message} /> :
                <div className="pb-40" >
                    <DialogModal
                        isOpen={modalOpen}
                        onOpenChange={setModalOpen}
                        onClose={() => { setModalOpen(false); setModalContent(null) }}
                        className={isVerticalModal ? "w-[501px] h-[660px]" : ""}
                        variant={!isVerticalModal ? "large" : ""}
                    >
                        {modalContent}

                    </DialogModal>

                    <Button variant="primary-negative" className="mt-[132px] ml-[120px]" onClick={() => { inpaintingView ? setInpaintingView(false) : navigate(`/image/new/${taskQueueId}`, { state: { generativeData } }); }}><><IconArrowLeft fill="white" />Return to image details</></Button>
                    <div className="pt-[24px] px-[120px] flex flex-row gap-10">
                        {generativeData && !inpaintingView ?
                            (
                                <div className="relative w-[600px] h-auto overflow-hidden rounded-lg">
                                    <img
                                        className="w-full h-auto object-cover"
                                        style={{ transform: `scale(${scale})`, transition: "transform 0.2s" }}
                                        src={selectedVariant?.permalink ?? ""}
                                        alt="Generated Image"
                                    />
                                    <Button onClick={handleZoomIn} className="absolute top-2 right-2 bg-white border-none rounded-lg" variant="outline-official">+</Button>
                                    <Button onClick={handleZoomOut} className="absolute top-2 right-9 bg-white border-none rounded-lg" variant="outline-official">-</Button>
                                </div>
                            ) : <ImageEditCanvas cursorSize={cursorSize} width={600} drawnPortion={drawnPortions} setDrawnPortion={setDrawnPortions} resetCanvas={resetCanvas} handleMaskGenerated={generateMask} image={selectedVariant?.permalink ?? ""} />
                        }
                        {inpaintingView ? <div className="flex flex-col gap-6">
                            <Heading as="h3">Click and paint to get started.</Heading>
                            <p className="font-semibold">Brush size</p>
                            <div className="flex gap-3">
                                <Button onClick={increaseCursorSize} className="bg-transparent w-[67px] h-[63px] rounded-xl border-gray-500 hover:bg-black hover:fill-white" variant="outline-official">+</Button>
                                <Button onClick={decreaseCursorSize} className="bg-transparent w-[67px] h-[63px] rounded-lg border-gray-500 hover:bg-black hover:fill-white" variant="outline-official">-</Button>
                            </div>
                            <p className="font-semibold">Prompt</p>
                            <textarea value={inpaintPrompt} onChange={(e) => setInpaintPrompt(e.target.value)} className="bg-gray-100 rounded-lg text-black w-[548px] h-[145px] focus:outline-none focus:ring-0 placeholder-black p-4" placeholder="Enter prompt here..."></textarea>
                            {isPromptLoading ? <Button className="w-52 border-none" onClick={enhanceTextPrompt} type="button" variant="primary">
                                <img className="h-4" src={loading} alt="Loading..." />
                            </Button> :
                                <Button onClick={enhanceTextPrompt} type="button" variant="primary" className="flex gap-2 border-none w-52 hover:bg-black hover:text-white hover:fill-white">
                                    <><Sparkles />Rewrite prompt</></Button>}
                            <p className="font-semibold">IP Vault</p>
                            <VaultDropdown modelData={modelList} selectedModelId={selectedModelId} onSelect={handleSelectModel} />
                            <div className="flex gap-3">
                                <Button onClick={resetCanvas} className="rounded-xl border-gray-500 text-black" variant="outline-official">Reset</Button>
                                <Button onClick={undoLastDraw} className="rounded-xl border-gray-500 text-black" variant="outline-official">Undo</Button>
                            </div>
                            {/* <Button onClick={generateInpaint}>Test inpaint</Button> */}
                        </div>

                            :
                            <div className="flex flex-col gap-6">
                                <Heading as="h3">Ta-da! Your image is ready.</Heading>
                                {variations.length > 0 &&
                                    <div>
                                        <p className="font-semibold pb-6">Variations</p>
                                        <div className="flex flex-row gap-7">
                                            {variations.map((variation, index) => (
                                                <div key={index} className="relative">
                                                    <img onClick={() => setSelectedVariant(variation)} className={`bg-gray-400 w-[116px] rounded-lg ${variation === selectedVariant ? "outline outline-4 outline-brand-yellow" : ""}`}
                                                        src={variation.permalink} />
                                                    {variation === selectedVariant &&
                                                        <div className="absolute top-2 right-2"> <SelectedGenIcon /> </div>}
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                }
                                <p className="font-semibold">Options</p>
                                <div className="grid md:grid-cols-2 gap-4 sm:grid-cols-1">
                                    {buttonMap.map((button, index) => (
                                        <Button key={index} variant="outline-official" onClick={button.action}
                                            className="h-[24px] w-full p-6 rounded-lg flex gap-2 justify-start overflow-hidden hover:fill-white">
                                            <>
                                                <div className="flex-shrink-0">{button.icon}</div>
                                                {button.label}</>
                                        </Button>
                                    ))}
                                </div>

                            </div>
                        }
                    </div>
                    {/* TODO clear toast */}
                    {actionToast.showToast && <div className="absolute rounded-3xl right-9 bg-black px-6 py-5 text-white w-[453px]">{actionToast.toastMessage}</div>}
                    <MinimalFooter buttonLabel="Generate image" disabled={!inpaintingView} onSubmit={inpaintingView ? generateInpaint : undefined} />
                </div>
            }

        </div>
    )

}


export default GeneratedImagePage;