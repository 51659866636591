import { useAuth0 } from "@auth0/auth0-react";
import { GenerateImageRequest, GenerativeModel, EnhancedPrompt, GenerateMediaResult, TaskQueueResponse, UpscaleRequest } from "../pages/image-generator/models/image_generator";

export function useGenerativeAPI() {
    const { getAccessTokenSilently } = useAuth0();

    const getModels = async (
        entity_type: string,
        // limit = 10,
        // offset = 0
    ): Promise<GenerativeModel[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<GenerativeModel[]>((resolve, reject) => {
            const params = new URLSearchParams();
            //   params.append("q", query);
            params.append("entity_type", entity_type);
            // params.append("limit", limit.toString());
            // params.append("offset", offset.toString());
            //   if (criteria) {
            //     params.append("criteria", JSON.stringify(criteria));
            //   }
            fetch(`${process.env.REACT_APP_API_URL}/generative-model/search?${params.toString()}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: GenerativeModel[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };

    const enhancePrompt = async (prompt: string, models?: string[]): Promise<EnhancedPrompt> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<EnhancedPrompt>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/generative/enhance-prompt`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ prompt, models }),
            }).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then((data: any) => {
                        resolve(data);
                    });
                } else {
                    if (response.status === 401) {
                        reject(new Error("User is not authenticated"));
                    } else {
                        reject(new Error("Failed to enhance: " + response.statusText));
                    }
                }
            });
        });
    };

    const generateImageMedia = async (saveRequest: GenerateImageRequest): Promise<any> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<any>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/generative/generate`;
            if (!saveRequest.num_images_per_prompt) {
                saveRequest.num_images_per_prompt = 2;
            }
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(saveRequest),
            })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((data: any) => {
                            resolve(data);
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }

    const checkTaskQueueStatus = async (taskId: string): Promise<any> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });
        return new Promise<any>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/task-queue/${taskId}/progress`;
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((data: any) => {
                            resolve(data);
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }

    const getGenerativeOutput = async (taskId: string): Promise<GenerateMediaResult> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });
        return new Promise<GenerateMediaResult>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/generative-output/task/${taskId}`;
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: GenerateMediaResult) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };

    const getMyImages = async (): Promise<GenerateMediaResult[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });
        return new Promise<GenerateMediaResult[]>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/generative-output/my`;
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: GenerateMediaResult[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("There was an issue retrieving images: " + response.statusText));
                }
            });
        });
    };

    const getModelById = async (modelId: string): Promise<GenerativeModel> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });
        return new Promise<GenerativeModel>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/generative-model/${modelId}`;
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: GenerativeModel) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("There was an issue retrieving model: " + response.statusText));
                }
            });
        });
    }

    // TODO: delete optional query param
    const deleteImage = async (taskQueueId: string): Promise<void> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });
        return new Promise<void>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/generative-output/task/${taskQueueId}`;
            fetch(url, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    reject(new Error("There was an issue deleting image: " + response.statusText));
                }
            });
        });
    };


    const saveMyImage = async (taskQueueId: string): Promise<void> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<void>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/generative-output/task/${taskQueueId}/save`;
            fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        resolve();
                    } else {
                        reject(new Error("There was an issue saving the image: " + response.statusText));
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject(new Error("User is not authenticated"));
                });
        });
    }

    const upscaleImage = async (upscaleRequest: UpscaleRequest): Promise<TaskQueueResponse> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD,
            },
        });

        return new Promise<TaskQueueResponse>((resolve, reject) => {
            const url = `${process.env.REACT_APP_API_URL}/generative/upscale`;
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(upscaleRequest),
            }).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then((data: any) => {
                        resolve(data);
                    });
                } else {
                    if (response.status === 401) {
                        reject(new Error("User is not authenticated"));
                    } else {
                        reject(new Error("Failed to upscale: " + response.statusText));
                    }
                }
            });
        });
    };

    return {
        getModels,
        enhancePrompt,
        generateImageMedia,
        checkTaskQueueStatus,
        getGenerativeOutput,
        getMyImages,
        getModelById,
        deleteImage,
        saveMyImage,
        upscaleImage
    };
}
