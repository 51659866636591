import React from "react";
import StepNavigator, { StepNavigatorProps } from "./step_navigator";
import Heading from "../../../common/components/heading";

interface StepWrapperProps extends StepNavigatorProps {
    children: React.ReactNode;
    optional?: boolean;
    heading: string;
    selectedModels: {
        product: any;
        brand: any;
        talent: any;
    }
}

const StepWrapper: React.FC<StepWrapperProps> = ({ children, optional, heading, selectedModels, ...StepNavigatorProps }) => {
    const stepsWithCheckMark = StepNavigatorProps.steps.map((step, index) => {
        switch (step.panel) {
            case "Product":
                return {
                    ...step,
                    hasSelectedModel: !!selectedModels.product
                };
            case "Brand":
                return {
                    ...step,
                    hasSelectedModel: !!selectedModels.brand
                };
            case "Talent":
                return {
                    ...step,
                    hasSelectedModel: !!selectedModels.talent
                };
            default:
                return {
                    ...step,
                    hasSelectedModel: false
                }
        }
    })
    return (
        <div>
            <StepNavigator {...StepNavigatorProps} steps={stepsWithCheckMark} />
            <div className="text-md font-semibold pt-[60px] pb-5">
                {optional ? "Optional" : "Required"}
            </div>
            <Heading as="small-h1">{heading}</Heading>
            {children}
        </div>
    )
}

export default StepWrapper;
